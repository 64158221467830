import React from "react";
import { AuthUserContext } from "./context";
import { withFirebase } from "../firebase";

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);

            /*
             * authUser:
             * - false: ei tiedossa ja ladataan
             * - null: ei kirjautunut
             * - Object: kirjautunut
             */
            this.state = {
                authUser: false
            };
        }

        componentDidMount() {
            if (this.props.firebase.auth != null) {
                this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
                    authUser ? this.setState({ authUser }) : this.setState({ authUser: null });
                });
            } else {
                this.setState({ authUser: null });
            }
        }

        componentWillUnmount() {
            if (this.listener != null) {
                this.listener();
            }
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }
    return withFirebase(WithAuthentication);
};
export default withAuthentication;
