import React, { Suspense, lazy, useState } from "react";
import { Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { withAuthentication, withProgress } from "./util/session";
import { domain } from "./util/host";
import UnauthenticatedRoute from "./util/route/unauthenticatedroute";

import "./App.scss";

const Login = lazy(() => import("./pages/login"));
const Voucher = lazy(() => import("./pages/voucher"));

const sites = {
    edukamu: lazy(() => import("./domains/edukamu")),
    "demo.edukamu": lazy(() => import("./domains/supertietokone")),
    "istoc.edukamu": lazy(() => import("./domains/istoc")),
    "saattohoitopassi.edukamu": lazy(() => import("./domains/saattohoitopassi")),
    "avoimetovet.edukamu": lazy(() => import("./domains/avoimetovet")),
    "kauppakamari.edukamu": lazy(() => import("./domains/kauppakamari")),
    "demo-digitaidot1.edukamu": lazy(() => import("./domains/demo-digitaidot1")),
    "digitaidot1.edukamu": lazy(() => import("./domains/digitaidot1")),
    "digitaidot2.edukamu": lazy(() => import("./domains/digitaidot2")),
    "covid19.edukamu": lazy(() => import("./domains/covid19")),
    "fb.covid19.edukamu": lazy(() => import("./domains/covid19")),
    "paihteet.edukamu": lazy(() => import("./domains/paihteet")),
    jumppakamu: lazy(() => import("./domains/jumppakamu")),
};

const languages = {
    edukamu: "fi",
    "demo.edukamu": "fi",
    "istoc.edukamu": "en",
    "saattohoitopassi.edukamu": "fi",
    "avoimetovet.edukamu": "fi",
    "kauppakamari.edukamu": "fi",
    "demo-digitaidot1.edukamu": "fi",
    "digitaidot1.edukamu": "fi",
    "digitaidot2.edukamu": "fi",
    "covid19.edukamu": "en",
    "fb.covid19.edukamu": "en",
    "paihteet.edukamu": "fi",
    jumppakamu: "fi",
};

const voucherCourses = {
    "covid19.edukamu": "N2LFBE0alUpkltxL",
    "fb.covid19.edukamu": "N2LFBE0alUpkltxL",
};

function App() {
    const dom = domain();

    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [hasVoucher, userHasVoucher] = useState(false);
    const appProps = {
        isAuthenticated: isAuthenticated,
        userHasAuthenticated: userHasAuthenticated,
        hasVoucher: hasVoucher,
        userHasVoucher: userHasVoucher,
        voucherCourse: voucherCourses[dom] || "",
    };

    const { i18n } = useTranslation();
    if (languages[dom] != null) {
        if (i18n.language !== languages[dom]) {
            i18n.changeLanguage(languages[dom]);
        }
    }

    return (
        <main>
            <Suspense fallback={<div></div>}>
                { GetRoutes(dom, appProps) }
            </Suspense>
        </main>
    );
}

function GetRoutes(domain, appProps) {

    if (Object.keys(sites).includes(domain)) {
        const LazyComponent = sites[domain];

        // covid sivustolla, yhdennä jos toimii oikein
        // Luodaan lazy komponentti jossa on <Switch> ja kaikki routet samalla tasolla jotta routet tulkitaan oikein
        if ( ["covid19.edukamu", "fb.covid19.edukamu", "digitaidot1.edukamu", "paihteet.edukamu"].includes(domain)) {
            return <LazyComponent appProps={appProps}>
                <UnauthenticatedRoute checkAuth={true} path="/login" exact component={Login} appProps={appProps} />
                <UnauthenticatedRoute
                    checkVoucher={true}
                    path="/voucher"
                    exact
                    component={Voucher}
                    appProps={appProps}
                />
            </LazyComponent>;
        }
        
        // tämä on aiempi jossa lazy komponentti on switch sisällä ja routet ei vaikuta olevan samanarvoisia.
        // istoc exam sivulla näkyy virhe, muutoin toiminee kuten pitää.
        // 08.05: Tämä aiheuttaa nähtävästi ongelmia myös avoimetovet sivustolla,
        // TODO: muutetaan kaikki domainit yläpuolella olevaan muotoon.
        return (
            <Switch>
                <UnauthenticatedRoute checkAuth={true} path="/login" exact component={Login} appProps={appProps} />
                <UnauthenticatedRoute
                    checkVoucher={true}
                    path="/voucher"
                    exact
                    component={Voucher}
                    appProps={appProps}
                />
                <LazyComponent appProps={appProps} />
            </Switch>
        );
    }

    return (
        <section className="p-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-10">
                        <article
                            className="article custom_Article"
                            style={{
                                minHeight: "200px",
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <p>Not found</p>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default withAuthentication(withProgress(App));
