import { domain } from "../host";

const istocConfig = {
    apiKey: "AIzaSyDBlmcCglta_DWldk4DP1Kc5hoMCQFEtOM",
    authDomain: "kamkhub-istoc-test.firebaseapp.com",
    databaseURL: "https://kamkhub-istoc-test.firebaseio.com",
    projectId: "kamkhub-istoc-test",
    storageBucket: "",
    messagingSenderId: "1064327619044",
    appId: "1:1064327619044:web:d3be8775e95da695"
};

const saattohoitopassiConfig = {
    apiKey: "AIzaSyAq4EpObdk1-VWxUL-Lf9uDXPxeRx2Zk9M",
    authDomain: "kamk-saattohoitopassi.firebaseapp.com",
    databaseURL: "https://kamk-saattohoitopassi.firebaseio.com",
    projectId: "kamk-saattohoitopassi",
    storageBucket: "kamk-saattohoitopassi.appspot.com",
    messagingSenderId: "409017435837",
    appId: "1:409017435837:web:b102f6af62e863505b99d4",
    measurementId: "G-SY56J9YVVC"
};

const kauppakamariConfig = {
    apiKey: "AIzaSyDQsOkUznW6papd-1_9duSOGbE3t6v63eU",
    authDomain: "edukamu-kauppakamari.firebaseapp.com",
    databaseURL: "https://edukamu-kauppakamari.firebaseio.com",
    projectId: "edukamu-kauppakamari",
    storageBucket: "edukamu-kauppakamari.appspot.com",
    messagingSenderId: "818447272335",
    appId: "1:818447272335:web:7958c4fa02deed760163fa",
    measurementId: "G-1768W4W2EC"
};

const avoimetovetConfig = {
    apiKey: "AIzaSyBzZTi8BMZ7UB5GTukAbFRzglVxsdQGmxs",
    authDomain: "kamk-hub-test.firebaseapp.com",
    databaseURL: "https://kamk-hub-test.firebaseio.com",
    projectId: "kamk-hub-test",
    storageBucket: "",
    messagingSenderId: "251331741168",
    appId: "1:251331741168:web:f69b639f36863aac"
};

const edukamuConfig = {
    apiKey: "AIzaSyDFfr8lYNUfd0unKbMtk_ClnpCA-l-iaLs",
    authDomain: "edukamu-92142.firebaseapp.com",
    databaseURL: "https://edukamu-92142.firebaseio.com",
    projectId: "edukamu-92142",
    storageBucket: "edukamu-92142.appspot.com",
    messagingSenderId: "522984874174",
    appId: "1:522984874174:web:6d84864c292d180d5ffeef",
    measurementId: "G-8XNMJW8LQ8"
};

const edukamuSandboxConfig = {
    apiKey: "AIzaSyDDw0K5zlx_K_9KRxiQ_DZOf4GJ4PSDZ3E",
    authDomain: "edukamu-sandbox.firebaseapp.com",
    databaseURL: "https://edukamu-sandbox.firebaseio.com",
    projectId: "edukamu-sandbox",
    storageBucket: "edukamu-sandbox.appspot.com",
    messagingSenderId: "587530549422",
    appId: "1:587530549422:web:50ec2af5a13dc872fc553f",
    measurementId: "G-9PZ92BJ114"
};

const configs = {
    "istoc.edukamu": istocConfig,
    "saattohoitopassi.edukamu": saattohoitopassiConfig,
    "kauppakamari.edukamu": kauppakamariConfig,
    "avoimetovet.edukamu": avoimetovetConfig,
    "demo-digitaidot1.edukamu": edukamuSandboxConfig,
    "digitaidot1.edukamu": edukamuConfig,
    "covid19.edukamu": istocConfig,
    "fb.covid19.edukamu": istocConfig,
    "paihteet.edukamu": edukamuConfig,
    "jumppakamu": edukamuConfig,
    "edukamu": edukamuConfig
};

export function getConfig(override) {
    return configs[override || domain()];
}
