import React from "react";

import { ProgressContext } from "./context";
import { withFirebase } from "../firebase";
import { domain } from "../host";

const istocIds = [
    "LFBE0alUpkltxN2L",
    "cvwQAcJcX2bb5eP",
    "cqBxtYNjT5nYbpey",
    "G6EW94NPZ6gGpKd8",
    "ZpaJs5Pwe492SX",
    "VzWesFPtdkf8Zq2",
];

const edukamuIds = [
    "RfDNCSzqcpv3wAp",
    "De8UYfHVUWWHMuwW",
    "W8Q4H7K74Gtkk3BP",
    "xew28PeJ7XjxuCxT",
    "U68xt72jfgAf2GB3",
    "LnbquUBnMMhYnSdh",
];

export function courseIds() {
    const ids = {
        "istoc.edukamu": istocIds,
        "saattohoitopassi.edukamu": ["JCLxbYd8WuEVPqnA"],
        "kauppakamari.edukamu": ["gEjfMQG7WSAa2K8c"],
        "avoimetovet.edukamu": [
            "K7eI2HswBWRavSYS",
            "fw7t1ggBcD2LmgND",
            "zYj9M4pMx0Q6uuk3",
            "fvqXJTnNetu6icBJ",
            "zpocMXkru9WGH1lo",
            "zpFouSxEkVuEzJiC",
        ],
        "demo-digitaidot1.edukamu": edukamuIds,
        "digitaidot1.edukamu": edukamuIds,
        "digitaidot2.edukamu": [],
        "covid19.edukamu": ["8eqgDe52FnJmHFSZ"],
        "fb.covid19.edukamu": ["8eqgDe52FnJmHFSZ"],
        "paihteet.edukamu": [
            null
        ],
        jumppakamu: [null, null, null, null, null, null],
        edukamu: [],
    };

    return ids[domain()];
}

const withProgress = (Component) => {
    class WithProgress extends React.Component {
        constructor(props) {
            super(props);

            /*
             * progress:
             * - false: lataus kesken
             * - null: ei kirjautunut
             * - Object: kirjautunut
             */
            this.state = {
                progress: false,
            };
        }

        componentDidMount() {
            if (this.props.firebase.auth == null) {
                return;
            }

            this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
                if (!authUser) {
                    this.setState({ progress: null });
                    return;
                }

                this.setState({ progress: {} }, () => {
                    this.props.firebase.watchAnswers(async (data) => {
                        var progress = {
                            totalPoints: 0,
                            totalPointsMax: 0,
                            totalAnswered: 0,
                            totalQuestionCount: 0,
                            courses: {},
                        };

                        if (!data) {
                            this.setState((prevState) => {
                                let resources = {};
                                if (prevState && prevState.progress) {
                                    resources = prevState.progress.resources || {};
                                }

                                progress.resources = resources;

                                let newState = {
                                    progress: progress,
                                };

                                return newState;
                            });

                            return;
                        }

                        for (const courseKey of courseIds()) {
                            var result = data[courseKey] || {
                                locked: false,
                                maxAttempts: 0,
                            };

                            if (["avoimetovet.edukamu", "digitaidot1.edukamu"].includes(domain())) {
                                var { questionCount } = await this.props.firebase
                                    .getCourseQuestions(courseKey)
                                    .then((questions) => {
                                        var questionCount = questions ? Object.keys(questions).length : 0;

                                        return {
                                            questionCount: questionCount,
                                        };
                                    })
                                    .catch((err) => {
                                        console.error(err);

                                        return {
                                            questionCount: 0,
                                        };
                                    });
                                result.questionCount = questionCount;
                            }

                            // Not in DB
                            result.answered = 0;
                            result.points = 0;
                            result.pointsMax = result.questionCount * 3;

                            for (var key of Object.keys(result)) {
                                if (
                                    [
                                        "timeLimitMinutes",
                                        "startTime",
                                        "answered",
                                        "locked",
                                        "maxAttempts",
                                        "points",
                                        "questionCount",
                                        "points",
                                        "pointsMax",
                                        "questionCount",
                                        "minCorrect",
                                        "resetTimeMinutes",
                                        "passed",
                                    ].includes(key)
                                ) {
                                    continue;
                                }

                                if (result[key].attempts) {
                                    result.points += result[key].points || 0;
                                    if (result[key].correct || result[key].storedAnswer) {
                                        result.answered++;
                                    } else if (result[key].attempts >= result.maxAttempts) {
                                        result.answered++;
                                    }
                                } else {
                                    result.points += result[key].correct ? 3 : 0;
                                    if (result[key].correct || result[key].storedAnswer) {
                                        result.answered++;
                                    }
                                }
                            }

                            progress.courses[courseKey] = result;
                            progress.totalQuestionCount += result.questionCount;
                            progress.totalPoints += result.points;
                            progress.totalAnswered += result.answered;
                        }

                        progress.totalPointsMax = progress.totalQuestionCount * 3;

                        this.setState((prevState) => {
                            let resources = {};
                            if (prevState && prevState.progress && prevState.progress.resources) {
                                resources = prevState.progress.resources;
                            }
                            progress.resources = resources;
                            let newState = {
                                progress: progress,
                            };

                            return newState;
                        });
                    });

                    this.props.firebase.watchProgress(async (data) => {
                        this.setState((prevState) => {
                            let progress = {};
                            let resources = {};
                            if (prevState && prevState.progress) {
                                progress = prevState.progress;
                                resources = prevState.progress.resources || {};
                            }

                            if (!data) {
                                data = {};
                            }

                            for (const courseKey of courseIds()) {
                                var result = data[courseKey] || {};
                                resources[courseKey] = result;
                            }

                            progress.resources = resources;

                            let newState = {
                                progress: progress,
                            };

                            return newState;
                        });
                    });
                });
            });
        }

        componentWillUnmount() {
            if (this.listener != null) {
                this.listener();
            }
        }

        render() {
            return (
                <ProgressContext.Provider value={this.state.progress}>
                    <Component {...this.props} />
                </ProgressContext.Provider>
            );
        }
    }
    return withFirebase(WithProgress);
};
export default withProgress;
