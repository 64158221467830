/*
    Functions for validating user input
*/

// HETU:n tarkistusmerkit
const numberToIDChar = {
    // HUOM: Osa kirjaimista hypätään yli, esim. G, I, O, yms.
    0: "0",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "A",
    11: "B",
    12: "C",
    13: "D",
    14: "E",
    15: "F",
    16: "H",
    17: "J",
    18: "K",
    19: "L",
    20: "M",
    21: "N",
    22: "P",
    23: "R",
    24: "S",
    25: "T",
    26: "U",
    27: "V",
    28: "W",
    29: "X",
    30: "Y",
};

export function validateFinnishID(input) {
    /* 
        Henkilötunnuksen muoto on PPKKVVSNNNT jossa:

        PPKKVV on syntymäaika.
            PP = kuukauden päivä, tarvittaessa etunollitettu.
            KK = kuukauden numero, tarvittaessa etunollitettu.
            VV = vuosiluvun kaksi viimeistä numeroa.
        S on vuosiluvun kaksi ensimmäistä numeroa osoittava välimerkki, vaihtoehdot: - A +
        NNNT on henkilötunnuksen loppuosa.
            NNN = yksilönumero, tarvittaessa etunollitettu, naisilla parillinen, miehillä pariton.
            T = tarkiste (kirjain tai numero).

        Esimerkki: 280726-976W
    */

    /* 
        Tarkistusmerkki kaava:

        Henkilötunnuksen ensimmäiset 9 lukua jaetaan luvulla 31.
        Tarkistusmerkki on jakojäännös.
        Jakojäännöksen ollessa suurempi kuin 9, muutetaan se kirjaimeksi:

        HUOM: Osa kirjaimista hypätään yli, esim. G, I, O, yms.

        0 = 0  
        1 = 1  
        2 = 2  
        3 = 3  
        4 = 4  
        5 = 5  
        6 = 6  
        7 = 7  
        8 = 8  
        9 = 9  
        10 = A
        11 = B
        12 = C
        13 = D 
        14 = E
        15 = F

        16 = H

        17 = J
        18 = K
        19 = L
        20 = M
        21 = N

        22 = P

        23 = R
        24 = S
        25 = T
        26 = U
        27 = V
        28 = W
        29 = X
        30 = Y 

        Esimerkki: 280726-976W
        280726976 % 31 = 28
    */

    if (typeof input !== "string") {
        console.error(`HETU type: ${input} (${typeof input})`);
        return false;
    }

    if (input.length !== 11) {
        console.error(`HETU length: ${input}`);
        return false;
    }

    // (6 x number 0-9) (- || + || A) (3 x number 0-9) (1 x number 0-9 or character A-Y)
    let re = /([0-9]){6}([-]|[+]|[A])([0-9]){3}([0-9]|[A-Y])/;

    let valid = re.exec(input);

    if (!valid) {
        console.error(`HETU regex: ${input}`);
        return false;
    }

    let id = valid[0];
    let day = id.substr(0, 2);
    let month = id.substr(2, 2);
    let year = id.substr(4, 2);
    // divider just tells us the century, is valid as long as it's either '-', '+' or 'A',
    // which is already checked in the regex above.
    //let divider = id.substr(6, 1);
    let unique = id.substr(7, 3);
    let validation = id.substr(10, 1);

    let numbers = day + month + year + unique;

    day = parseInt(day);
    month = parseInt(month);
    year = parseInt(year);
    numbers = parseInt(numbers);

    if (day < 0 || day > 31) {
        console.error(`HETU day: ${day}`);
        return false;
    }

    if (month < 0 || month > 12) {
        console.error(`HETU month: ${month}`);
        return false;
    }

    if (year < 0) {
        console.error(`HETU year: ${year}`);
        return false;
    }

    if (numbers < 0) {
        console.error(`HETU numbers: ${numbers}`);
        return false;
    }

    let expectedRemainder = numberToIDChar[numbers % 31];

    if (validation !== expectedRemainder.toString()) {
        console.error(`HETU validation: ${validation} !== ${expectedRemainder}`);
        return false;
    }

    return true;
}
