import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";

import { getConfig } from "./config";
import { validateFinnishID } from "../validate";

class Firebase {
    constructor() {
        const firebaseConfig = getConfig();
        if (firebaseConfig != null) {
            app.initializeApp(firebaseConfig);
            this.auth = app.auth();
        }

        // Edukamu firebase project is used for login before rendering websites
        const edukamuConfig = getConfig("edukamu");
        if (edukamuConfig != null) {
            this.edukamuApp = app.initializeApp(edukamuConfig, "edukamu");
        }
    }

    doSignInWithEmailAndPassword = (email, password) => {
        return new Promise((resolve) => {
            this.auth.signInWithEmailAndPassword(email, password).then(() => {
                resolve();
            });
        });
    };

    doSignOut = () => {
        return new Promise((resolve) => {
            this.auth.signOut().then(() => {
                resolve();
            });
        });
    };

    sendEmailVerification(resend) {
        return new Promise((resolve, reject) => {
            var user = this.auth.currentUser;
            if (user) {
                app.database()
                    .ref("users/" + user.uid)
                    .once("value", (snapshot) => {
                        var send = false;
                        var value = snapshot.val();

                        // Send if this is the first one
                        if (!value) {
                            send = true;
                        } else {
                            if (!value.verification_sent) {
                                send = true;
                            } else {
                                // Check if we've already sent verification in the last minute if
                                if (resend && Date.now() - value.verification_sent > 60000) {
                                    send = true;
                                }
                            }
                        }

                        if (send) {
                            // Send verification
                            user.sendEmailVerification()
                                .then(() => {
                                    // Update verification_sent time
                                    app.database()
                                        .ref("users/" + user.uid)
                                        .update({
                                            verification_sent: Date.now(),
                                        });
                                    resolve();
                                })
                                .catch((error) => {
                                    console.log(error);
                                    reject();
                                });
                        } else {
                            // minuutin resend aika palautuu tässä
                            resolve();
                        }
                    });
            }
        });
    }

    async getUserById(uid) {
        try {
            var userSnap = await app
                .database()
                .ref("users/" + uid)
                .once("value");
            return userSnap.val();
        } catch (e) {
            console.log(e);
        }
        return null;
    }

    async updateUser(uid, data) {
        return new Promise((resolve, reject) => {
            if (!uid || !data) {
                reject("Invalid data");
                return;
            }

            // lähetetään tietyt kentät
            app.database()
                .ref("users/" + uid)
                .update(
                    {
                        first_name: data.first_name ? data.first_name : "",
                        surname: data.surname ? data.surname : "",
                        institution: data.institution ? data.institution : "",
                        institution_id: data.institution_id ? data.institution_id : "",
                        institution_idnumber: data.institution_idnumber ? data.institution_idnumber : "",
                        institution_role: data.institution_role ? data.institution_role : "",
                        institution_role_id: data.institution_role_id ? data.institution_role_id : "",
                        student_id: "",
                        national_id: data.national_id ? data.national_id : "",
                        date_of_birth: data.date_of_birth ? data.date_of_birth : "",
                    },
                    (error) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve();
                        }
                    }
                );
        });
    }

    async updateUserSaatohoitopassi(uid, data) {
        return new Promise((resolve, reject) => {
            if (!uid || !data) {
                reject("Invalid data");
                return;
            }

            // lähetetään tietyt kentät
            app.database()
                .ref("users/" + uid)
                .update(
                    {
                        surname: data.surname || "",
                        first_names: data.first_names || "",
                        preferred_name: data.preferred_name || "",
                        national_id: data.national_id || "",
                        nationality: data.nationality || "",
                        first_language: data.first_language || "",
                        country: data.country || "",
                        phone_number: data.phone_number || "",
                        address: data.address || "",
                        postal_code: data.postal_code || "",
                        postal_district: data.postal_district || "",
                    },
                    (error) => {
                        if (error) {
                            reject(error);
                        } else {
                            resolve();
                        }
                    }
                );
        });
    }

    async isProfileFilled() {
        try {
            var user = this.auth.currentUser;

            var profileSnap = await app
                .database()
                .ref("users/" + user.uid)
                .once("value");
            var profile = profileSnap.val();

            if (!profile) {
                return false;
            }

            var requiredFields = [
                "surname",
                "first_names",
                "preferred_name",
                "national_id",
                "nationality",
                "first_language",
                "country",
            ];

            // Check if required fields exist
            for (let field of requiredFields) {
                if (!profile[field] || profile[field].length === 0) {
                    return false;
                }
            }

            // Check if ID is valid
            return validateFinnishID(profile.national_id);
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async getSchools() {
        try {
            var schoolsSnap = await app.database().ref("schools/").once("value");
            var schoolsValue = schoolsSnap.val();
            if (schoolsValue != null) {
                return schoolsValue.items;
            }
        } catch (e) {
            console.log(e);
        }
        return null;
    }

    async getSchoolRoles() {
        try {
            var rolesSnap = await app.database().ref("roles_in_school/").once("value");
            var rolesValue = rolesSnap.val();
            if (rolesValue != null) {
                return rolesValue.items;
            }
        } catch (e) {
            console.log(e);
        }
        return null;
    }

    async getQuestion(course, question) {
        return new Promise(function (resolve, reject) {
            app.database()
                .ref("courses/" + course + "/questions/" + question)
                .once("value", (snapshot) => {
                    resolve(snapshot.val());
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getCourseQuestions(course) {
        return new Promise(function (resolve, reject) {
            app.database()
                .ref("courses/" + course + "/questions/")
                .once("value", (snapshot) => {
                    resolve(snapshot.val());
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async answerQuestion(course, question, answer) {
        return new Promise((resolve, reject) => {
            var addAnswer = app.functions().httpsCallable("addAnswer");
            addAnswer({ course: course, question: question, answer: answer })
                .then((result) => {
                    if (result.data) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async startCourse(course) {
        return new Promise((resolve, reject) => {
            var start = app.functions().httpsCallable("startCourse");
            start({ course: course })
                .then((result) => {
                    if (result.data) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async completeCourse(course) {
        return new Promise((resolve, reject) => {
            var complete = app.functions().httpsCallable("completeCourse");
            complete({ course: course })
                .then((result) => {
                    if (result.data) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getCourseProgress = (course) => {
        return new Promise((resolve, reject) => {
            var progress = app.functions().httpsCallable("getProgress");
            progress({ course: course })
                .then((result) => {
                    if (result.data) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    watchAnswers = (cb) => {
        var user = this.auth.currentUser;
        if (!user) {
            return;
        }

        app.database()
            .ref("user_answers/" + user.uid)
            .on("value", (snapshot) => {
                cb(snapshot.val());
            });
    };

    watchProgress = (cb) => {
        var user = this.auth.currentUser;
        if (!user) {
            return;
        }

        app.database()
            .ref("user_progress/" + user.uid)
            .on("value", (snapshot) => {
                cb(snapshot.val());
            });
    };

    async resetCourse(course) {
        return new Promise((resolve, reject) => {
            var reset = app.functions().httpsCallable("resetCourse");
            reset({ course: course })
                .then((result) => {
                    if (result.data) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async markSeen(course, resource) {
        return new Promise((resolve, reject) => {
            var mark = app.functions().httpsCallable("markSeen");
            mark({ course: course, resource: resource })
                .then((result) => {
                    if (result.data) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async getCompletionPdf(course) {
        return new Promise((resolve, reject) => {
            var pdf = app.functions().httpsCallable("getCompletionPdf");
            pdf({ course: course })
                .then((result) => {
                    if (result.data) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async siteLogin(username, password) {
        return new Promise((resolve, reject) => {
            // always use edukamu firebase project
            var login = this.edukamuApp.functions().httpsCallable("siteLogin");
            login({ username: username, password: password })
                .then((result) => {
                    if (result.data !== null && result.data !== undefined) {
                        return resolve(result.data);
                    }
                    reject(
                        "Jokin meni pieleen. Ole hyvä ja yritä uudelleen myöhemmin. Jos ongelma jatkuu, ota yhteyttä järjestelmän ylläpitäjään."
                    );
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async checkAccessToken(token) {
        return new Promise((resolve, reject) => {
            // always use edukamu firebase project
            var check = this.edukamuApp.functions().httpsCallable("checkAccessToken");
            check({ token: token })
                .then((result) => {
                    if (result.data !== null && result.data !== undefined) {
                        return resolve(result.data);
                    }
                    reject(
                        "Jokin meni pieleen. Ole hyvä ja yritä uudelleen myöhemmin. Jos ongelma jatkuu, ota yhteyttä järjestelmän ylläpitäjään."
                    );
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Check if user has a valid voucher for the course
     * @param {*} course - Course ID
     */
    async hasValidVoucher(course) {
        return new Promise((resolve, reject) => {
            var check = app.functions().httpsCallable("hasValidVoucher");
            check({ course: course })
                .then((result) => {
                    if (result.data !== null && result.data !== undefined) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Attempt to redeem a voucher
     * @param {*} course - Course ID
     * @param {*} voucher - Voucher ID
     */
    async redeemVoucher(course, voucher) {
        return new Promise((resolve, reject) => {
            var redeem = app.functions().httpsCallable("redeemVoucher");
            redeem({ course: course, voucher: voucher })
                .then((result) => {
                    if (result.data !== null && result.data !== undefined) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Assigns an anonymous answer to user for peer review.
     * Only questions with 'peerReview' key are valid for this.
     *
     * If an answer has already been assigned to user with the
     * course and question ids supplied, any future calls
     * to this function will return the answer already assigned.
     *
     * @param {*} course - Course ID
     * @param {*} question - Question ID
     */
    async getPeerReviewAnswer(course, question) {
        return new Promise((resolve, reject) => {
            var answer = app.functions().httpsCallable("getPeerReviewAnswer");
            answer({ course: course, question: question })
                .then((result) => {
                    if (result.data !== null && result.data !== undefined) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Adds a rating to a peer review answer assigned to user.
     * getPeerReviewAnswer() must be called succesfully
     * with the same course and question ids before this.
     *
     * @param {*} course - Course ID
     * @param {*} question - Question ID
     * @param {*} rating - Rating (1-5)
     */
    async addPeerReviewRating(course, question, rating) {
        return new Promise((resolve, reject) => {
            var rate = app.functions().httpsCallable("addPeerReviewRating");
            rate({ course: course, question: question, rating: rating })
                .then((result) => {
                    if (result.data !== null && result.data !== undefined) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async addContactRequest(email, phoneNumber, contactType, role) {
        return new Promise((resolve, reject) => {
            var request = app.functions().httpsCallable("addContactRequest");
            request({ email: email, phoneNumber: phoneNumber, contactType: contactType, role: role })
                .then((result) => {
                    if (result.data !== null && result.data !== undefined) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async sendContactForm(name, email, company, message) {
        return new Promise((resolve, reject) => {
            var send = app.functions().httpsCallable("sendContactForm");
            send({ name: name, email: email, company: company, message: message })
                .then((result) => {
                    if (result.data !== null && result.data !== undefined) {
                        return resolve(result.data);
                    }
                    reject("Invalid data");
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
export default Firebase;
