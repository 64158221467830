const DEFAULT_LOCAL_DOMAIN = "paihteet.edukamu";

export const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function subDomain(hostname) {
    hostname = hostname || window.location.hostname;
    var parts = hostname.split(".");

    while (["www", "local", "staging"].includes(parts[0])) {
        parts.splice(0, 1);
    }

    var sub = parts[0];

    if (sub.includes("staging-")) {
        sub = sub.split("staging-")[1];
    }

    if (["127", "192", "localhost", "edukamu"].includes(sub)) {
        // no subdomain
        sub = "";
    }

    // Legacy typo
    if (sub === "saattohoito") {
        sub = "saattohoitopassi";
    }

    return sub;
}

function isExtension(text) {
    return ["fi", "com"].includes(text);
}

function isNotDomain(text) {
    return ["127", "192", "localhost"].includes(text);
}

export function domain(hostname) {
    hostname = hostname || window.location.hostname;
    var parts = hostname.split(".");

    while (["www", "local", "staging"].includes(parts[0])) {
        parts.splice(0, 1);
    }

    var dom = parts[0];

    if (dom.includes("staging-")) {
        dom = dom.split("staging-")[1];
    }

    // Legacy typo
    if (dom === "saattohoito") {
        dom = "saattohoitopassi";
    }

    if (isNotDomain(dom)) {
        dom = DEFAULT_LOCAL_DOMAIN;
    } else {
        for (let i = 1; i < parts.length; i++) {
            if (isExtension(parts[i])) {
                break;
            }

            if (isNotDomain(parts[i])) {
                dom += ".edukamu";
                break;
            }
            dom += "." + parts[i];
        }
    }

    return dom;
}
